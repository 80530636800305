<template>
  <div class="calc-page">

    <div class="svg">
      <svg width="200" :height="200">
        <defs>
          <filter id="shadow" x="-20%" y="-20%" width="140%" height="140%">
            <feDropShadow dx="2" dy="5" stdDeviation="5" flood-color="rgba(0, 0, 0, 0.5)"/>
          </filter>
        </defs>
        <path d="M 199.25 87.81 A 100 100 0 0 0 113.92 0.97 L 100 100 Z" fill="#00adb5" filter="url(#shadow)"></path>
      </svg>
    </div>
    <el-dialog v-model="visiable" :close-on-click-modal="false" @close="closeDialog">
      <div class="card-header">
        <h1>污染边界层高度</h1>
      </div>
      <div class="dialog-class">
        <el-card style="width: 400px">
          <div>
            <h3>上一个小时数据</h3>
          </div>
          <el-form ref="ruleFormRef" style="max-width: 600px" :model="prevForm" status-icon
                   label-width="auto" class="demo-ruleForm">
            <el-form-item label="请输入超级站数据" prop="num1">
              <el-input v-model="prevForm.num1" autocomplete="off"/>
            </el-form-item>
            <el-form-item label="请输入辉山大街数据" prop="num2">
              <el-input v-model="prevForm.num2" autocomplete="off"/>
            </el-form-item>
            <el-form-item label="请输入李相数据" prop="num3">
              <el-input v-model="prevForm.num3" autocomplete="off"/>
            </el-form-item>
            <el-form-item label="请输入黄腊坨数据" prop="num4">
              <el-input v-model="prevForm.num4" autocomplete="off"/>
            </el-form-item>
          </el-form>
        </el-card>
        <el-card style="width: 400px">
          <div>
            <h3>当前小时数据</h3>
          </div>
          <el-form ref="ruleFormRef" style="max-width: 600px" :model="nowForm" status-icon
                   label-width="auto" class="demo-ruleForm">
            <el-form-item label="请输入超级站数据" prop="num1">
              <el-input v-model="nowForm.num1" autocomplete="off"/>
            </el-form-item>
            <el-form-item label="请输入辉山大街数据" prop="num2">
              <el-input v-model="nowForm.num2" autocomplete="off"/>
            </el-form-item>
            <el-form-item label="请输入李相数据" prop="num3">
              <el-input v-model="nowForm.num3" autocomplete="off"/>
            </el-form-item>
            <el-form-item label="请输入黄腊坨数据" prop="num4">
              <el-input v-model="nowForm.num4" autocomplete="off"/>
            </el-form-item>

          </el-form>
        </el-card>
      </div>
      <div class="footer">

        <div class="footer-button">
          <el-select
              v-model="selectHour"
              placeholder="选择当前小时"
              size="large"
              style="width: 240px;margin-right: 8px"
          >
            <el-option
                v-for="item in options.value"
                :key="item"
                :label="item"
                :value="item"
            />
          </el-select>
          <el-button type="primary" @click="submitForm()">
            生成结果
          </el-button>
<!--          <el-button type="success" @click="resetForm(ruleFormRef)">重置</el-button>-->
<!--          <el-button type="danger" @click="copy">复制结果到剪切板</el-button>-->
        </div>
        <el-card style="max-width: 850px;width: 850px">
          {{ repeateResult }}
        </el-card>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>

import {defineEmits, onMounted, reactive, ref} from 'vue';


const visiable = ref(true)
const selectHour = ref('')

const options = [];

const prevForm = reactive({
  num1: '',
  num2: '',
  num3: '',
  num4: '',

})

onMounted(() => {
  let tempOptions = [];
  for (let i = 1;i <=24;i++){
    tempOptions.push(i);
  }
  options.value = tempOptions
})

const nowForm = reactive({
  num1: '',
  num2: '',
  num3: '',
  num4: '',
})


const repeateResult = ref('');

const emits = defineEmits(['addEvent']) //addEvent是父元素里面的一个函数方法，通过这个方法传参

const closeDialog = () => {
  emits('addEvent', "污染边界层高度")
}
const submitForm = () => {
  let result1 = nowForm.num1 - prevForm.num1;
  let result2 = nowForm.num2 - prevForm.num2;
  let result3 = nowForm.num3 - prevForm.num3;
  let result4 = nowForm.num4 - prevForm.num4;
  let month = new Date().getMonth() + 1;
  let day = new Date().getDate();
  repeateResult.value = `${month}月${day}日${selectHour.value}时 （实时）污染边界层高度分别为
   超级站${nowForm.num1}(较${selectHour.value - 1}时 （实时）${result1 === 0 ? '持平' : ( result1 > 0 ? '上升' + Math.abs(result1) : '下降' + Math.abs(result1))} )
  ,辉山大街${nowForm.num2}(较${selectHour.value - 1}时 （实时）${result2 === 0 ? '持平' : ( result2 > 0 ? '上升'+ Math.abs(result2) : '下降'+ Math.abs(result2))})
  ,李相${nowForm.num3}(较${selectHour.value - 1}时 （实时）${result3 === 0 ? '持平' : ( result3 > 0 ? '上升'+ Math.abs(result3) : '下降'+ Math.abs(result3))})
  ,黄腊坨${nowForm.num4}(较${selectHour.value - 1}时 （实时）${result4 === 0 ? '持平' : ( result4 > 0 ? '上升'+ Math.abs(result4) : '下降'+ Math.abs(result4))})
  `;


}


</script>

<style scoped>
.dialog-class {
  display: flex;
  justify-content: space-around;

}

.card-header {
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
}

.footer {
  width: 100%;
  height: 200px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
}
.footer-button{
  text-align: right;
  width: 850px;
}


</style>
